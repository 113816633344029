/**
 * Contact form
 */

 const form = document.querySelector('#contactForm')
 const nameInput = form.querySelector('#name')
 const emailInput = form.querySelector('#email')
 const messageInput = form.querySelector('#message')
 const inputs = [nameInput, emailInput, messageInput]

form.addEventListener('submit', (e) => {
    e.preventDefault()
    if (!nameInput.value) {
        nameInput.parentElement.classList.add('error')
        return
    } else {
        nameInput.parentElement.classList.remove('error')
    }
    if (!emailInput.value || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput.value)) ) {
       emailInput.parentElement.classList.add('error')
       return
   } else {
       emailInput.parentElement.classList.remove('error')
   }
   if (!messageInput.value) {
       messageInput.parentElement.classList.add('error')
       return
   } else {
       messageInput.parentElement.classList.remove('error')
   }
//    const data = new FormData(form)
})

inputs.forEach(input => {
    input.addEventListener('input',() => {
        input.parentElement.classList.remove('error')
    })
})