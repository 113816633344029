/**
 * Menu links
 */
const easeInOutCubic = t => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1

const menu = {
    header: document.querySelector('#js-header'),
    menuButton: document.querySelector('#js-menuButton'),
    links: document.querySelectorAll('header a'),
    scrollInterval: null,
    setUp: function() {
        this.menuButton.addEventListener('click', () => {
            menu.header.classList.toggle('visible')
        })
        this.links.forEach(link => {
            link.addEventListener('click',(e) => {
                e.preventDefault()
                const destination = document.querySelector(link.getAttribute('href'))
                const finalScrollTop = Math.max(destination.offsetTop - (sizes.height / 2) + (destination.offsetHeight / 2) - 40, 0)
                const startScrollPosition = window.scrollY
                const offset = finalScrollTop - startScrollPosition
                const stepsNumber = Math.abs(offset / 2000) * 60
                menu.header.classList.remove('visible')
                for (let i = 0; i < stepsNumber; i++) {
                    setTimeout(() => {
                        const newScrollValue = startScrollPosition + (offset * easeInOutCubic(i / stepsNumber))
                        window.scrollTo(0,newScrollValue)
                    }, i * 1000/60)
                }
            })
        })
    }
}

menu.setUp()