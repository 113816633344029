 /**
  * Scroll management
  */
const backgound = document.querySelector('#js-background')
const scrollAnimatedElements = document.querySelectorAll('.scrollAnimated')
let scrollAnimatedViews

const easeInOutQuad = t => t<.5 ? 2*t*t : -1+(4-2*t)*t

document.addEventListener('scroll', () => {
    const scrollY = window.scrollY
    let imageUpdated = false
    scrollAnimatedViews.forEach(view => {
        const relativePos = (view.posY - scrollY) / sizes.height
        view.elem.style.opacity = 1 - easeInOutQuad(Math.abs(relativePos))
        view.elem.style.transform = `translateX(${relativePos * 10}vw) rotate(${relativePos * 15}deg)`
        if (!imageUpdated && relativePos > -0.5 && relativePos < 0.5) {
            const imageClass = `show${view.elem.getAttribute('data-image')}`
            if (!backgound.classList.contains(imageClass)) {
                backgound.classList.remove(`show0`, `show1`, `show2`, `show3`, `show4`)
                backgound.classList.add(`show${view.elem.getAttribute('data-image')}`)
            }
            imageUpdated = true
        }
    })
})

/**
 * Manage sizes calculation
 */
function setScrollAnimatedViews() {
    scrollAnimatedViews = Array.prototype.slice.call(scrollAnimatedElements).map((elem) => {
        return {
            elem: elem,
            posY: elem.offsetTop - (sizes.height / 2) + (elem.offsetHeight / 2)
        }
    })
}

setScrollAnimatedViews()

document.addEventListener('reszie', setScrollAnimatedViews)